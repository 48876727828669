.isi-term{
    position: relative;
    &::before{
        content: '';
        background: rgba(137, 11, 41, 0.10);
        filter: blur(147px);
        width: 100%;
        height: 500px;
        position: absolute;
    }

    &.no-before::before {
        content: none; 
    }
    .wrapper{
        padding-top: 100px;
        padding-bottom: 100px;
        .wrapp-text{
            display: flex;
            flex-direction: column;
            gap: 16px;
            .text{
                display: flex;
                flex-direction: column;
                gap: 16px;
                p{
                    margin: 0;
                    &.title{
                        color: var(--Base-Black, #191919);
        
                        /* Text/M/SemiBold */
                        font-family: Outfit;
                        font-size: 22px;
                        font-style: normal;
                        font-weight: 600;
                        line-height: normal;
                    }
        
                    &.desc{
                        color: var(--Text-Secondary, #575757);
        
                        /* Text/S/Regular */
                        font-family: Outfit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 25px; /* 156.25% */
                    }
                    
                    &.h-desc{
                        color: #191919 !important;
                        font-family: Outfit;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 700;
                        line-height: 25px; /* 156.25% */
                    }
                }

                ul{
                    margin-left: 20px;
                }
            }
        }
    }
}