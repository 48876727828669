.navbar-wrapper {
  background-color: white;

  h1,
  .menu {
    color: #eaa;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    text-align: end;

    &.dekstop {
      font-size: 16px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #523A48;
    }

    // &.active {
    //   color: #de8d8d;
    //   font-weight: 600;
    // }
  }

  .menu-in-drawer {
    width: 100%;
    max-height: 0;
    background: FFF0F0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 0 25px;
    transition: all 0.5s;
    overflow: hidden;
    margin-top: 74px;
  }

  .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 24px;
    position: relative;

    .center {
      display: none;
    }

    .right {
      display: flex;
      gap: 14px;
      align-items: center;

      .language {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: $gray-9e;
        border-radius: 900px;
        border: 0.5px solid #ddd;
        background: #fff;
        padding: 3px 12px;

        span {
          font-weight: 700;
          color: #de8d8d;
        }
      }
    }
  }

  @media (width >= 834px) {
    .icon-menu {
      display: none;
    }

    h1 {
      font-size: 14px;
    }

    .menu-desktop {
      display: flex;
      gap: 24px;
      align-items: center;
    }

    .navbar {
      padding: 25px 90px;

      .center {
        display: block;
      }

      .right {
        .language {
          font-size: 16px;
          border: none;
        }
      }
    }
  }

  @media (width >= 1200px) {
    h1 {
      font-size: 16px;
    }

    .menu-desktop {
      gap: 35px;
    }

    .navbar {
      padding: 25px 200px;
    }
  }
}
