.card-job-list {
  width: 100%;
  padding: 16px;
  //   border-radius: 5.6px;
  //   border: 0.7px solid #fee6e6;
  //   background-color: white;
  //   box-shadow: 0px 1.4px 12.6px 0px rgba(24, 25, 28, 0.03) inset,
  //     0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  border: 1px solid #fee6e6;
  background-color: white;
  box-shadow: 0px 2px 18px 0px rgba(24, 25, 28, 0.03);

  display: flex;
  gap: 15px;
  flex-direction: column;

  p {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: $gray-9e;
    margin-bottom: 0;

    &.desc {
      color: #575757;
      ul,ol{
        margin-left: 20px;
      }
    }
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $base-black;
    margin-bottom: 0;
  }

  .images {
    padding: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6.485px;
    background: #fee6e6;
    width: 60px;
    height: 70px;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  hr {
    margin: 0;
    border: 1px solid #fff5f5;
  }

  .top {
    display: flex;
    gap: 8px;
    align-items: center;

    .right {
      display: flex;
      gap: 5px;
      flex-direction: column;
      flex-grow: 1;
    }

    .place {
      display: flex;
      gap: 3px;
    }

    .category {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .bottom {
    display: flex;
    gap: 10px;
    flex-direction: column;

    .recap {
      display: flex;
      gap: 14px;
      align-items: center;

      .item {
        display: flex;
        gap: 5px;
        align-items: center;
      }
    }
  }

  button {
    border-radius: 7px;
    padding: 9px 15px;
    font-size: 16px;
    width: fit-content;
  }

  @media (width >= $bp-big-tablet) {
    padding: 24px;
    h2{
        font-size: 18px;
    }

    p{
        font-size: 12px;
        line-height: 20px;
    }

    .top{
        .right{
            gap: 3px;
        }
    }

    .bottom{
        gap: 5px;
    }
  }
}
