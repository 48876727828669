@import "./../utils/variables";

.title-header {
  width: 100%;
  padding: 15px 20px;
  background-color: #fff5f5;

  .wrapper {
    display: flex;
    flex-direction: column;
    max-width: 1000px;
  }

  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    color: #523a48;
    margin-bottom: 0;
  }

  h2 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    color: $gray-9e;
    margin-bottom: 0;

    span {
      color: $text-primary;
    }
  }

  @media (width >= $bp-tabet) {
    padding: 24px 50px;

    .wrapper {
      margin: auto;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }
  }
}
