.card-product {
  flex-grow: 1;
  padding-right: 16px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-items: center;
  gap: 14px;
  overflow: hidden;
  border-radius: 16px;
  background: #fff;
  box-shadow: 0px 1px 15.1px 0px rgba(151, 144, 144, 0.25);

  .product-image {
    width: 90px;
    height: 100%;
    object-fit: cover;
  }

  .product-cart {
    box-shadow: 0px 3.06567px 23.75891px 2.29925px rgba(246, 151, 145, 0.57);
    border-radius: 50%;
  }

  .text {
    padding: 14px 0;
    display: flex;
    gap: 5px;
    flex-direction: column;
    flex-grow: 1;

    .category {
      color: var(--Gray-500, #9F9F9F);
      font-size: 8.4px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }

    .promo {
      width: fit-content;
      padding: 2.1px 4.2px;
      border-radius: 4.2px;
      background: #fad43b;
      font-size: 8.4px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: white;
    }

    h1 {
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 17.5px;
      color: $primary-400;
      margin-bottom: 0;
      max-width: 170px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .price {
      font-size: 16.8px;
      font-style: normal;
      font-weight: 600;
      line-height: 18.9px;
      color: $primary-400;
    }

    hr {
      border: 0.7px solid #e8e8e9;
      margin: 0;
    }
  }

  @media (width >=$bp-tabet) {
    flex-direction: column;
    flex-wrap: nowrap;
    padding-right: 0;
    gap: 0;
    position: relative;
    // flex-grow: 1;

    .text {
      width: 100%;
      padding: 20px 10px;
      // padding-top: 25px;


    }

    .product-image {
      height: 205px;
      width: 100%;
    }

    .product-cart-desktop {
      position: absolute;
      top: 64%;
      left: 50%;
      transform: translate(-50%, -50%);
      border-radius: 43.208px;
      background: #de8d8d;
      box-shadow: 0px 7.50947px 58.19839px 5.6321px rgba(246, 151, 145, 0.57);
    }
  }

  @media (width >=$bp-big-tablet) {
    .product-image {
      height: 300px;
    }

    .product-cart-desktop {
      &.xl {
        top: 65%;
      }
    }

    .text {
      gap: 10px;
      padding: 20px;
      // padding-top: 50px;
      width: 100%;

      h1 {
        max-width: 300px;
        font-size: 22px;
      }

      .promo {
        font-size: 12px;
      }

      .category {
        font-size: 18px;
      }

      .price {
        line-height: 27px;
        font-size: 24px;
      }
    }
  }

  @media (width >=$bp-laptop) {

    .text {
      gap: 10px;
      padding: 20px;
      // padding-top: 50px;
      width: 100%;

      h1 {
        max-width: 300px;
        font-size: 24px;
      }

      .promo {
        font-size: 12px;
      }

      .category {
        font-size: 20px;
      }

      hr{
        margin-top: 10px !important;
      }

      .price {
        line-height: normal;
        font-size: 32px;

        span{
          margin-right: 5px;
        }
      }
    }
  }
}