.detail-testimoni {
  padding: 25px;
  border-radius: 10px;
  border: 1px solid #fee6e6;
  background: #fff;
  box-shadow: 0px 4px 26px 0px rgba(238, 170, 170, 0.18);
  // gap: 20px;
  gap: 13px;
  display: flex;
  flex-direction: column;
  position: relative;

  h1 {
    // font-size: 21px;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: #de8d8d;
    margin-bottom: 0;
  }

  h3 {
    // font-size: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    text-transform: lowercase;
    color: rgba(223, 130, 124, 0.5);
    margin-bottom: 0;
  }

  h2 {
    // font-size: 20px;
    text-align: start !important;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    // line-height: 33px;
    line-height: normal;
    color: $gray-9e;
    margin-bottom: 10px !important;
  }

  p,
  a {
    // font-size: 20px;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    // line-height: 33px;
    line-height: 22px;
    color: #575757;
    margin-bottom: 0;
  }

  a {
    //   color: #575757;
    //   // font-size: 20px;
    //   font-size: 12px;
    //   font-style: normal;
    //   font-weight: 400;
    //   line-height: 33px;
    //   margin-bottom: 0;
    text-decoration-line: underline !important;
  }

  .top {
    display: flex;
    gap: 5px;
    align-items: center;

    img {
      width: 48px;
      height: 48px;
      object-fit: cover;
    }
  }

  .bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;

    button {
      display: flex;
      align-items: center;
      gap: 5px;
      // padding: 8px 14px;
      padding: 3.967px 10.942px;
      border-radius: 39.733px;
      border: 1.5px solid #de8d8d;
      // box-shadow: 0px 14px 21px -3px rgba(246, 151, 145, 0.57) !important;
      // font-size: 14.45px;
      font-size: 10px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      color: #de8d8d;
      background-color: transparent;
      margin-bottom: 0;
    }
  }

  @media (width >= $bp-big-tablet) {
    gap: 20px;

    h1 {
      font-size: 21px;
    }
    h2,
    h3,
    p,
    a {
      font-size: 20px;
      line-height: 33px;
    }

    p {
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;
    }

    .bottom {
      button {
        font-size: 15px;
        display: flex;
        padding: 8px 14px;
      }

      img {
        width: 37px;
        height: 37px;
      }
    }
  }

  @media (width >= $bp-laptop) {
    height: 357px;

    .bottom {
      position: absolute;
      left: 25px;
      bottom: 25px;
      right: 25px;
    }
  }
}
