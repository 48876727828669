.medsos{
    display: flex;
    flex-direction: column;
    gap: 5px;
    position: fixed;
    // position: absolute;
    z-index: 2;
    top: 35%;
    left: 10px;
    transform: translateY(35%);
    .card-medsos{
        display: flex;
        padding: 8px;
        gap: 10px;
        border-radius: 8px;
        background: #DE8D8D;
            img{
                width: 24px;
                height: 24px;
            }
    }
    
    @media (width >= 768px) {
        gap: 15px;
        // top: 28%;
        left: 2%;
        // transform: translateY(28%);
        .card-medsos{
            padding: 8px;
            gap: 10px;
            border-radius: 8px;
            background: #DE8D8D;
            // img{
            //     width: 24px;
            //     height: 24px;
            // }
        }
    }

    // @media (width >= 992px) {
    //     top: 28%;
    //     transform: translateY(28%);
    // }
}