.no-job{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    .text{
        display: flex;
        flex-direction: column;
        gap: 15px;
        &-top{
            margin: 0;
            color: var(--Base-Black, #191919);
            text-align: center;
            font-family: Outfit;
            font-size: 32px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            span{
                color: var(--Primary-400, #DE8D8D);
                font-family: Outfit;
                font-size: 32px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }
        }

        &-bottom{
            color: #8B8787;
            text-align: center;
            font-family: Outfit;
            font-size: 20px;
            font-style: normal;
            font-weight: 400;
            line-height: 29px; /* 145% */
        }
    }

    img{
        width: 500px;
    }
}