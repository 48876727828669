.about-us {

    h2 {
        font-size: 32px;
        font-weight: 600;
        color: #523A48;
    }

    p {
        font-size: 20px;
        line-height: 44px;
        color: #575757;
    }

    img {
        &.left {
            position: absolute;
            top: 0;
            left: 0;
            transform: rotate(180deg) scaleY(-1);
        }

        &.right {
            position: absolute;
            top: 0;
            right: 0;
        }

        &.ellipse-pink {
            width: 100%;
            position: absolute;
            top: 0;
            left: 0;
            z-index: -1;
        }
    }

    .wrapper {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 65px
    }

    .hero {
        position: relative;

        .title-hero {
            width: 100%;
            position: absolute;
            top: 40%;

            h1 {
                font-size: 55px;
                font-weight: 700;
                text-align: center;
                color: white;

                span {
                    color: #DE8D8D;
                }

                @media (width<=768px) {
                    font-size: 30px;
                }
            }

            p {
                text-align: center;
                color: white;
            }
        }
    }

    .our-value {
        position: relative;
        padding: 100px 100px 40px;

        .text-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            h2 {
                font-size: 49px;
                text-align: center;
                color: #191919;

                span {
                    color: #DE8D8D;
                }
            }

            p {
                width: 67%;
                text-align: center;
                font-size: 24px;
                color: #8B8787;
            }
        }

        .our-value-wrapper {
            display: flex;
            flex-direction: column;

            .our-value-item {
                display: flex;
                position: relative;
                align-items: center;
                gap: 80px;

                img {
                    &.pattern-health {
                        position: absolute;
                        right: 0;
                        top: -70px;
                    }


                    &.health {
                        margin-right: 30px;
                        z-index: 2;
                    }
                }
            }
        }
    }

    .our-history {
        position: relative;
        padding: 100px 0;

        .text-title {
            padding: 0 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            h2 {
                font-size: 55px;
                text-align: center;
                color: #191919;

                span {
                    color: #DE8D8D;
                }
            }

            p {
                width: 899px;
                text-align: center;
            }
        }

        .our-history-wrapper {
            padding: 35px 100px 0;
            display: flex;
            flex-direction: column;
            gap: 65px;

            .our-history-item {
                display: flex;
                position: relative;
                align-items: center;
                gap: 100px;

                img {
                    &.pattern-history1 {
                        position: absolute;
                        left: -30px;
                        top: -50px;
                        z-index: -1;
                    }

                    &.pattern-history2 {
                        position: absolute;
                        right: -30px;
                        top: -50px;
                        z-index: -1;
                    }
                }
            }
        }
    }

    .our-team {
        padding: 100px;
        position: relative;
        background-color: #DE8D8D;

        img {
            &.pattern-team {
                width: 100%;
                position: absolute;
                top: -240px;
                left: 0;
            }
        }

        .text-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;
            z-index: 1;

            h2 {
                font-size: 55px;
                text-align: center;

                span {
                    color: white;
                }
            }

            p {
                width: 899px;
                text-align: center;
                color: white;
            }
        }

        .our-team-wrapper {
            display: flex;
            justify-content: center;
            gap: 40px;
            z-index: 1;

            .our-team-item {
                display: flex;
                flex-direction: column;
                gap: 15px;

                .text {
                    display: flex;
                    flex-direction: column;
                    gap: 4px;

                    h2 {
                        margin: 0;
                        font-size: 20px;
                        color: white;
                        letter-spacing: 0.1px;
                    }

                    p {
                        margin: 0;
                        font-size: 18px;
                        line-height: 20px;
                        color: white;
                    }
                }
            }
        }
    }

    .our-gallery {
        position: relative;
        padding: 100px;

        .text-title {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            h2 {
                margin: 0;
                font-size: 55px;
                text-align: center;
                color: #191919;

                span {
                    color: #DE8D8D
                }
            }

            p {
                width: 899px;
                text-align: center;
            }

            ;
        }

        .our-gallery-wrapper {
            display: flex;
            position: relative;
            justify-content: center;
            gap: 40px;

            .pattern-ellipse {
                position: absolute;
                bottom: 0;
                right: 0;
            }

            .our-gallery-item {
                display: flex;
                flex-direction: column;
                gap: 40px;
            }
        }
    }

    .testimoni {
        padding: 100px 0;
        position: relative;

        .text-title {
            padding: 0 100px;
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 15px;

            h2 {
                font-size: 55px;
                text-align: center;
                color: #191919;

                span {
                    color: #DE8D8D;
                }
            }

            p {
                width: 899px;
                text-align: center;
            }
        }

        .testimoni-wrapper {
            .swiper {
                .swiper-slide {
                    width: 70%;
                    display: flex;
                    justify-content: center;

                    &.swiper-slide-prev,
                    &.swiper-slide-next {
                        opacity: 0.3;
                    }

                    &.swiper-slide-active {
                        opacity: 1;
                    }
                }

                .navigation-item {
                    cursor: pointer;
                    width: 100%;
                    padding: 0 100px;
                    display: flex;
                    justify-content: space-between;
                    position: absolute;
                    bottom: 225px;
                    z-index: 100;
                }
            }
        }
    }

    .affiliate {
        position: relative;
        padding: 100px 200px;

        img {
            &.img-affiliate {
                position: absolute;
                top: -105px;
                left: 80px;
                width: 552px;
            }

            &.pattern-affiliate {
                width: 88%;
                height: 214px;
                position: absolute;
                z-index: -1;
                top: 180px;
                left: 100px;
            }
        }

        .affiliate-wrapper {
            padding: 42px 0 42px 485px;
            height: 340px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            gap: 28px
            ;
            background-color: #DE8D8D;
            border-radius: 19px;

            .text-title {
                width: 430px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                h2 {
                    margin: 0;
                    font-size: 16px;
                    color: #FFFFFF;
                    font-weight: 400;
                }

                h1 {
                    margin: 0;
                    font-size: 30px;
                    font-weight: 700;
                    color: #FFFFFF;
                }

                p {
                    margin: 0;
                    font-size: 16px;
                    font-weight: 300;
                    line-height: 26px;
                    color: #FFFFFF;
                }
            }

            a {
                width: 55%;
                display: flex;
                justify-content: center;
                padding: 16px 40px;
                background-color: #FFFFFF;
                font-size: 24px;
                color: #DE8D8D;
                border-radius: 34px;
                gap: 10px;
            }
        }
    }

    .referensi{
        display: flex;
        flex-direction: column;
        gap: 30px;
        
        .wrapper{
            padding-top: 100px;
            padding-bottom: 100px;
            display: flex;
            flex-direction: column;
            gap: 30px;

            p{
                margin: 0;
                &.title{
                    color: #575757;
                    font-family: Outfit;
                    font-size: 32px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 34px; /* 106.25% */
                }
    
                &.sub-title{
                    color: #575757;
                    font-family: Outfit;
                    font-size: 22px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 26px; /* 118.182% */
                }

                @media (width <=768px){
                    &.title{
                        font-size: 20px;
                    }

                    &.sub-title{
                        font-size: 16px;
                    }
                }
            }
    
            ul,ol{
                margin-left: 20px;
                li{
                    color: #575757;
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 35px;
                    // text-decoration-line: underline;
                }
            }
        }
    }
}