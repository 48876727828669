.article-card-item {
  h1 {
    font-size: 22px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
    color: $base-black;
    margin-bottom: 0;
    text-align: start !important;
    width: 100% !important;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2; /* number of lines to show */
    line-clamp: 2;
    -webkit-box-orient: vertical;
    // height: 90px;
    // max-height: 90px;
  }

  // p {
  //   // height: 60px;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  //   display: -webkit-box;
  //   -webkit-line-clamp: 3;
  //   -webkit-box-orient: vertical;
  //   // background-color: blue;
  //   text-align: start;
  //   font-size: 15px;
  //   font-style: normal;
  //   font-weight: 400;
  //   line-height: 23px;
  //   color: $gray-9e !important;
  //   margin-bottom: 5px;
  // }

  .button-custom {
    display: flex;
    // height: 51.667px;
    padding: 15px 37.2px;
    justify-content: center;
    align-items: center;
    gap: 10.333px;
    border-radius: 93px;
    background: var(--Primary-400, #de8d8d);
    font-size: 20px;
    box-shadow: none;
    margin-top: 10px;
  }

  button {
    padding: 10.333px 37.2px;
  }

  width: 100%;
  min-height: 223px;
  margin-top: 35px;
  border-radius: 10px;
  overflow: hidden;
  background: #fff;
  border: 1px solid rgba($color: $gray-9e, $alpha: 0.2);
  margin-bottom: 15px;
  //   box-shadow: 0px 2.76726px 2.21381px 0px rgba(206, 194, 194, 0.02),
  //     0px 6.6501px 5.32008px 0px rgba(206, 194, 194, 0.03),
  //     0px 12.52155px 10.01724px 0px rgba(206, 194, 194, 0.04),
  //     0px 22.33631px 17.86905px 0px rgba(206, 194, 194, 0.04),
  //     0px 41.77761px 33.42209px 0px rgba(206, 194, 194, 0.05),
  //     0px 100px 80px 0px rgba(206, 194, 194, 0.07);

  .text {
    padding: 20px;
    background-color: white;
    display: flex;
    flex-direction: column;
    gap: 15px;

    // h1 {
    //   font-size: 28.933px;
    //   font-style: normal;
    //   font-weight: 600;
    //   line-height: 150%;
    //   color: #523a48;
    //   overflow: hidden;
    //   display: -webkit-box;
    //   -webkit-line-clamp: 2; /* number of lines to show */
    //   line-clamp: 2;
    //   -webkit-box-orient: vertical;
    // }

    hr {
      display: none;
    }

    p {
      max-height: 112px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 4; /* number of lines to show */
      line-clamp: 4;
      -webkit-box-orient: vertical;

      h2{
        text-align: start;
      }
    }

    p,
    a,
    h2,
    h3,
    h4,
    h5,
    h6,
    li {
      font-size: 18.6px;
      font-style: normal;
      font-weight: 400;
      line-height: 150%;
      margin-bottom: 0;
      color: #9f9f9f;

      h1 {
        font-size: 18.6px;
        font-style: normal;
        font-weight: 400;
        line-height: 150%;
        color: #9f9f9f;
      }
    }
  }

  @media (width >=$bp-tabet) {
    margin-top: 50px;

    h1 {
      font-size: 25px;
      line-height: normal;
    }

    p {
      font-size: 10px;
      line-height: 14px;
    }

    .button-custom {
      font-size: 12px;
      padding: 10px 20px;
    }
  }

  @media (width >=$bp-big-tablet) {
    margin-top: 0;

    h1 {
      font-size: 22px;
    }

    p {
      font-size: 15px;
      line-height: 23px;
    }

    .button-custom {
      font-size: 20px;
      // padding: 20px;
    }
  }
}
