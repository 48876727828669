input,
select,
textarea {
  transition: 0.3s all ease-in-out;
  outline: none;
  padding: 10px 15px 10px 20px;
  border-radius: 5px;
  border: 1px solid #e1dbdb;
  background: #f9f9f9;
  width: 100%;

  &::placeholder {
    font-size: 16px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    color: $gray-9e;
  }

  &:active,
  &:focus {
    border: 1px solid $primary-400;
  }
}

select {
  appearance: none;
  background-image: url("data:image/svg+xml,%3Csvg width='15' height='16' viewBox='0 0 15 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M3.75 5.65625L7.05806 8.96431C7.26639 9.17264 7.37056 9.27681 7.5 9.27681C7.62944 9.27681 7.73361 9.17264 7.94194 8.96431L11.25 5.65625' stroke='%239E9E9E' stroke-width='0.9375' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: top 50% right 11px;
  background-size: 20px;
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  color: $gray-9e;
}
