.button-custom {
  display: flex;
  align-items: center;
  gap: 5px;
  padding: 4.238px 20px;
  border-radius: 90000px;
  background: #de8d8d;
  box-shadow: 0px 1.56527px 12.13084px 1.17395px rgba(255, 160, 154, 0.49);
  width: fit-content;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  color: white;
}
