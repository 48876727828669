// @import "./../../utils/variables";

.article-details-wrapper {
  h1 {
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    color: #de8d8d;

    margin: 10px 0;
  }

  h5 {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 25px;
    color: $gray-9e;
  }

  h2 {
    // font-size: 24px;
    font-size: 20px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $text-primary;
  }

  p,
  li {
    font-size: 16px;
    // font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 175%;
    color: $text-secondary;
  }

  .desc-wrapper {
    padding: 50px 5px;

    .heading {
      margin-bottom: 30px;

      h5,
      h1 {
        text-align: center;
      }
    }

    section {
      h2 {
        margin-bottom: 30px;
      }

      a {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 35px;
        text-decoration-line: underline;
        color: $primary-400;
      }

      p {
        margin-bottom: 5px;
        a {
          text-decoration: underline;
          color: inherit;
        }
      }

      ul {
        margin-left: 15px;
      }

      margin-bottom: 25px;
    }
  }

  @media (width >= $bp-tabet) {
    .desc-wrapper {
      padding: 50px 50px;
    }
  }

  @media (width >= $bp-big-tablet) {
    p,
    li {
      font-size: 20px;
    }

    h2 {
      font-size: 24px;
    }

    .images{
        img{
            width: 100%;
            height: 620px;
            object-fit: cover;
        }
    }

    .desc-wrapper{
        padding: 100px 50px;

        .heading{
            margin-bottom: 65px;
        }

        section{
            a{
                font-size: 20px;
            }
        }
    }
  }
}
