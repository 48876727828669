.card-bayi{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5.357px;
    padding: 5.357px;
    flex: 1 0 0;
    max-width: 111px;

    border-radius: 3.214px;
    background: var(--White, #FFF);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    .wrapp-img{
        display: flex;
        align-items: center;
        gap: 6px;
        flex: 1 0 0;
        .img-bayi{
            border-radius: 3.214px;
            max-height: 69.375px;
        }
    }
    .wrapp-text{
        display: flex;
        justify-items: center;
        p{  
            max-width: 50px;
            width: 50px;
            margin: 0;
            height: 15px;
            color: #6C4231;
            text-align: center;
            font-family: Outfit;
            font-size: 12px;
            font-style: normal;
            font-weight: 500;
            line-height: 15px; /* 175% */
            &.text{
                border-right: 1px solid #6C4231;
            }
        }
    }

    @media (width >=$bp-tabet) {
        padding: 11px;
        gap: 11px;
        max-width: 228px;
        border-radius: 7px;
        .wrapp-img{
            gap: 11px;
            .img-bayi{
                border-radius: 7px;
                max-height: 142px;
            }
        }
        .wrapp-text{
            p{
                max-width: 103px;
                width: 103px;
                font-size: 12px;
                height: 20px;
            }
        }
    }

    @media (width >=$bp-big-tablet){
        padding: 14.171px;
        gap: 14.171px;
        max-width: 295px;
        border-radius: 9px;
        .wrapp-img{
            gap: 15px;
            .img-bayi{
                border-radius: 9px;
                max-height: 184px;
            }
        }
        .wrapp-text{
            p{
                max-width: 133px;
                width: 133px;
                font-size: 14.171px;
                height: 25px;
            }
        }
    }

    @media (width >=$bp-laptop) {
        padding: 20px;
        gap: 20px;
        max-width: 416px;
        border-radius: 12px;
        .wrapp-img{
            gap: 20px;
            .img-bayi{
                border-radius: 12px;
                max-height: 259px;
            }
        }
        .wrapp-text{
            p{
                max-width: 188px;
                width: 188px;
                font-size: 20px;
                height: 35px;
            }
        }
    }
}