.banner-referensi{
    background: var(--Primary-400, #DE8D8D);
    display: flex;
    padding: 50px 40px;
    flex-direction: column;
    align-items: flex-start;
    gap: 40px;
    align-self: stretch;
    .wrapp{
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;

        @media (width <= 768px){
            gap: 50px;
            flex-direction: column;
        }

        &-text{
            display: flex;
            flex-direction: column;
            gap: 10px;
            p{
                margin: 0;
                &.title{
                    color: var(--Base-White, var(--White, #FFF));
                    font-family: Outfit;
                    font-size: 30px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 34px; /* 113.333% */
                }
                &.sub-title{
                    color: var(--Base-White, var(--White, #FFF));
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 26px; /* 162.5% */
                }
            }
        }
        .referensi{
            display: flex;
            padding: 16px 40px;
            justify-content: center;
            align-items: center;
            gap: 5px;
            border-radius: 34px;
            background: var(--White, #FFF);
            box-shadow: 0px 5.909px 45.795px 4.432px rgba(255, 160, 154, 0.49);
            color: var(--Primary-400, #DE8D8D);
            font-family: Outfit;
            font-size: 24px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            margin: 0;
        }
    }
}