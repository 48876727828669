.wrapp-card-base{
    display: flex;
    flex-direction: column;
    gap: 40px;
    .card-ingredients{
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 25px;
        align-self: stretch;
        .wrapp-image{
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            gap: 12.15px;
            align-self: center;
            width: 325px;
            // height: 329.412px;

            img{
                width: 100%;
                height: 100%;
            }
            p{
                width: 100%;
                color: #575757;
                text-align: center;
                font-family: Outfit;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24.706px; /* 123.529% */
                margin: 0;
                span{
                    color: #DA8383;
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 24.706px;
                }
            }
        }
        .wrapp-text{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
            flex: 1 0 0;
            p{
                margin: 0;
                &.title{
                    color: var(--Text-Primary, #523A48);
                    font-family: Outfit;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }
                &.desc{
                    color: #575757;
                    font-family: Outfit;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 32px; /* 160% */
                }
            }

            .button-selengkapnya{
                cursor: pointer;
                display: flex;
                height: 51.667px;
                padding: 10.333px 20px;
                justify-content: center;
                align-items: center;
                gap: 10.333px;

                border-radius: 93px;
                background: var(--Primary-400, #DE8D8D);

                color: var(--Base-White, var(--White, #FFF));
                font-family: Outfit;
                font-size: 16px;
                font-style: normal;
                font-weight: 600;
                line-height: normal;
            }

            span{
                &.text-lihat-lebih-sedikit{
                    color: #DA8383;
                    font-family: Outfit;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 32px;
                    text-decoration-line: underline;
                }
            }
        }
    }

    @media (width >= 768px) {
        gap: 33px;
        .card-ingredients{
            display: flex;
            flex-direction: row !important;
            gap: 44px;
            
            
        }
    }

    @media(width >= 992px){
        gap: 60px;
        .card-ingredients{
            gap: 80px;
            .wrapp-image{
                gap: 16.471px;
                width: 441px;
                p{  
                    font-size: 20px;
                    span{
                        font-size: 20px;
                    }
                }
            }
            
            .wrapp-text{
                gap: 20px;
                p{
                    &.title{
                        font-size: 32px;
                    }
                    &.desc{
                        font-size: 20px;
                    }
                }
                .button-selengkapnya{
                    font-size: 18.6px;
                }
                span{
                    &.text-lihat-lebih-sedikit{
                        font-size: 16px;
                    }
                }
            }
        }
    }
}