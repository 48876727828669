@import "./../../utils/variables";

.career-detail {
  // padding: 100px 0;
  padding: 20px 5px;

  h3, p, li {
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    color: #9e9e9e;
    margin-bottom: 0;

    &.ln-25 {
      line-height: 25px;
    }
  }

  h2 {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $base-black;
  }

  h1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    color: $base-black;
    margin: 2px 0;
  }

  p,li{
    line-height: 25px;
    color: #575757;
  }

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 10px;

    .left {
      display: flex;
      gap: 12px;
      align-items: start;

      .icon-job {
        width: 63px;
        height: 63px;
        object-fit: cover;
      }

      .address {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        // gap: 20px;
        column-gap: 10px;
        row-gap: 3px;

        .item {
          display: flex;
          align-items: center;
          gap: 7px;
        }
      }
    }

    .right {
      flex-grow: 1;
      width: 100%;

      button {
        width: 100%;
        padding: 17px 30px;
        border-radius: 6px;
        border: 1px solid #de8d8d;
        background: #de8d8d;
        justify-content: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        color: white;
        display: flex;
        align-items: center;
        gap: 7px;
      }
    }
  }

  .job-desc-wrapper{
    display: flex;
    flex-direction: column;
    gap: 16px;

    .job-desc{
      display: flex;
      flex-direction: column;
      gap: 15px;
      padding-bottom: 15px;
    }

    ul{
      margin-left: 15px;
    }

    section{
      h2{
        margin-bottom: 8px;
      }
    }
  }

  @media (min-width: 768px) {
    padding: 50px;
    .top {
      align-items: start;
      gap: 20px;
    }
  }

  @media (min-width: 992px) {
    padding: 50px;
    .top {
      align-items: start;
      gap: 20px;
      flex-direction: row;
      align-items: center;

      .right {
        width: fit-content;
        flex-grow: 0;
      }
    }
  }

  @media (min-width: 1200px) {
    padding: 100px 0;
  }
}
