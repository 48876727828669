.timeline-line {
    width: 0.5px;
    height: 50px;
    z-index: 222;
    /* Lebar garis */
    background-color: #DE8D8D;
    /* Warna garis */
    position: absolute;
    left: 10px;
    /* Menempatkan garis di tengah */
    margin-top: 20px;
    top: 0;
    bottom: 0;
    /* margin-left: -1px; */
    /* Menggeser garis ke kiri sejauh setengah lebar */
}

.timeline-with-icons .timeline-item {
    position: relative;
    margin-left: 10px;
}

.timeline-with-icons .timeline-item:after {
    position: absolute;
    display: block;
    top: 0;
}

.timeline-with-icons .timeline-icon {
    position: absolute;
    /* left: -48px; */
    background-color: #DE8D8D;
    color: hsl(217, 88.8%, 35.1%);
    border-radius: 50%;
    height: 20px;
    width: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media (max-width: 768px) {
    .timeline-line {
        height: 60px;
    }
}

.inner-detail-alamat {
    justify-content: center;
    /* text-align: center; */
    margin-bottom: -20px;
}

/* ini batas */
.mt-70 {
    margin-top: 40px;
}

.mb-40 {
    margin-bottom: 40px;
}

.card {
    box-shadow: 0 0.46875rem 2.1875rem rgba(4, 9, 20, 0.03), 0 0.9375rem 1.40625rem rgba(4, 9, 20, 0.03), 0 0.25rem 0.53125rem rgba(4, 9, 20, 0.05), 0 0.125rem 0.1875rem rgba(4, 9, 20, 0.03);
    border-width: 0;
    transition: all .2s;
}

.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(26, 54, 126, 0.125);
    border-radius: .25rem;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.vertical-timeline {
    width: 100%;
    position: relative;
    padding: 1.5rem 0 1rem;
}

.vertical-timeline::before {
    content: '';
    position: absolute;
    top: 10;
    left: 30%;
    height: 80%;
    width: 0.5px;
    background: #DE8D8D;
    border-radius: .25rem;
}
@media (max-width: 768px) {
    .vertical-timeline::before {
        left: 150px;
        height: 84%;
    }
}

.vertical-timeline-element {
    position: relative;
    margin: 0 0 1rem;
}

.vertical-timeline-element-icon {
    position: absolute;
    top: 0;
    left: 29%;
    width: 20px;
    height: 20px;
    border-radius: 25px;
    background-color: #DE8D8D;

}
@media (max-width: 768px) {
    .vertical-timeline-element-icon{
        left: 140px;
    }
}



.vertical-timeline-element-content {
    position: relative;
    margin-left: 90px;
    font-size: .8rem;
}

@media (max-width: 768px) {
    .vertical-timeline-element-content {
        margin-left: 50px;
    }
}

.vertical-timeline-element-content .timeline-title {
    color: var(--Text-Primary, #333435);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    margin-left: 230px;
}

.timeline-title p{
    color: var(--Text-Primary, #333435);
    font-family: Outfit;
    font-size: 14px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.vertical-timeline-element-content .vertical-timeline-element-date {
    display: block;
    position: absolute;
    left: -45px;
    top: 0;
    padding-right: 10px;
    text-align: right;
    color: var(--Text-Primary, #333435);
    font-family: Outfit;
    font-size: 12px;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
}

.vertical-timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
}