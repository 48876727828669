.article-home-page {
  .modal-share-article {
    padding: 20px;
    .modal-content {
      border-radius: 12px;
      overflow: hidden;
      border: none;

      .modal-body {
        padding: 0;
        background: #fff;
        position: relative;

        .close-icon{
          position: absolute;
          top: 20px;
          right: 20px;
        }

        .text {
          padding: 30px 20px;
          gap: 21px;
          display: flex;
          flex-direction: column;

          h1 {
            text-align: center;
            width: 100%;
          }

          p {
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 23px;
            color: #9e9e9e;
            margin-bottom: 0;
          }

          button {
            margin: 0 auto;
            padding: 13px 30px;
            color: white;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
        }
      }
    }
  }

  .hero {
    img {
      height: 300px;
      width: 100%;
      object-fit: cover;
    }
  }

  h1 {
    // font-size: 24px;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: normal;
    text-transform: capitalize;
    color: $primary-400;
    margin-bottom: 0;
  }

  h2 {
    // font-size: 16px;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 23px;
    color: $gray-9e;
    margin-bottom: 0;

    span {
      //   font-size: 22px;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      color: $primary-400;
    }
  }

  // .swiper-wrapper {
  //   flex-direction: unset !important;
  //   flex-wrap: wrap;
  // }

  .swiper-slide-dekstop {
    display: none;
  }

  .swiper-pagination {
    margin-top: 25px;
    display: inline-flex;
    gap: 5px;
    width: fit-content !important;
    // width: 50px;
    // height: 50px;
    // overflow: visible !important;

    .swiper-pagination-bullet {
      // scale: 5.5;
      display: block;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background-color: #d9d9d9;

      &-active {
        background-color: $primary-400;
      }
    }
  }

  .search-bar {
    margin: 50px 0;
    position: relative;
    input {
      padding: 10px 5px 10px 30px;
      height: 54px;
      border-radius: 90px;
      border: 1px solid #f8c4c4;
      background: #fff5f5;
      width: 100%;

      &::placeholder {
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #f8c4c4;
      }
    }

    img {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  .wrapper {
    margin: 50px 5px;

    .top {
      h2 {
        margin-top: 20px;
      }
    }

    .navigation-carousel {
      margin-top: 25px;
      display: flex;
      justify-content: end;
      align-items: center;
      // transform: translateY(-30px);

      .navigation-item {
        cursor: pointer;
        gap: 10px;
        display: flex;
        align-items: center;
      }
    }

    .category {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .item {
        width: 100%;
        margin: 0 auto;
        display: flex;
        justify-content: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;
        color: #f8c4c4;
        padding: 15px 0;
        border-bottom: 1px solid #f8c4c4;
        // border: none;
        outline: none;

        &.react-tabs__tab--selected {
          color: $primary-400;
          font-weight: 500;
          border-bottom: 3px solid #f8c4c4;
        }
      }
    }

    .article-card-wrapper {
      margin: 50px 0;

      a {
        &:hover {
          text-decoration: none;
        }
      }

      .top {
        display: flex;
        gap: 15px;
        flex-direction: column;
        padding: 20px;
        border-radius: 10px;
        background: #fee6e6;
        box-shadow: 0px 2.76726px 2.21381px 0px rgba(206, 194, 194, 0.02),
          0px 6.6501px 5.32008px 0px rgba(206, 194, 194, 0.03),
          0px 12.52155px 10.01724px 0px rgba(206, 194, 194, 0.04),
          0px 22.33631px 17.86905px 0px rgba(206, 194, 194, 0.04),
          0px 41.77761px 33.42209px 0px rgba(206, 194, 194, 0.05),
          0px 100px 80px 0px rgba(206, 194, 194, 0.07);

        h1 {
          font-size: 22px;
          font-style: normal;
          font-weight: 700;
          line-height: 32px;
          color: $base-black;
          margin-bottom: 0;
        }

        p {
          text-align: start;
          font-size: 15px;
          font-style: normal;
          font-weight: 400;
          line-height: 23px;
          color: #523a48;
          margin-bottom: 5px;
        }

        &.desktop {
          display: none;
        }

        button {
          display: inline-flex;
          padding: 4.238px 20px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          border-radius: 90000px;
          background: #de8d8d;
          box-shadow: 0px 1.56527px 12.13084px 1.17395px
            rgba(255, 160, 154, 0.49);
          color: white;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          width: fit-content;
        }
      }
    }
  }

  @media (width >= $bp-tabet) {
    .hero {
      img {
        height: 480px;
      }
    }

    h1 {
      text-align: center;
      font-size: 20px;
      width: 65%;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
      font-size: 15px;

      span {
        font-size: 20px;
      }
    }

    .wrapper {
      margin: 50px 50px;

      .article-card-wrapper {
        &.no-carousel {
          .wrapper-card {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 25px;
            justify-content: space-between;
            align-items: center;

            .article-card-item {
              margin-top: 0;
            }
          }
        }

        .top {
          h1 {
            width: 100%;
            text-align: start;
          }
        }
      }
    }
  }

  @media (width >= $bp-big-tablet) {
    .swiper-slide-dekstop {
      display: block;
    }

    .hero {
      img {
        height: 619px;
      }
    }

    h1 {
      text-align: center;
      font-size: 24px;
      width: 685px;
      margin: 0 auto;
    }

    h2 {
      text-align: center;
      font-size: 16px;

      span {
        font-size: 22px;
      }
    }

    .search-bar {
      margin: 65px 0;
    }

    .wrapper {
      margin: 100px 50px;

      .category {
        flex-direction: row;
        flex-wrap: nowrap;
      }

      .article-card-wrapper {
        &.no-carousel {
          .wrapper-card {
            grid-template-columns: repeat(3, 1fr);
          }
        }

        .top {
          display: none;

          h1 {
            width: 100%;
            text-align: start;
          }

          &.desktop {
            display: flex;
            min-height: 450px;
            // align-items: center;
            justify-content: center;
          }
        }
      }
    }
  }

  @media (width >= $bp-laptop) {

    .afiliator{
      margin: 130px 0;

      .join-reseller{
        // padding: 200px 100px !important;
        padding-bottom: 65px !important;
      }
    }

    .modal-share-article{
      .modal-content{
        .modal-body{
          .text{
            button{
              padding: 16px 40px;
              font-size: 24px;
            }
          }
        }
      }
    }

    .wrapper {
      .article-card-wrapper {
        &.no-carousel {
          .wrapper-card {
            grid-template-columns: repeat(4, 1fr);
            gap: 35px;
          }
        }
      }
    }
  }
}
