@import "./../utils/font.scss";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: $outfit;
}

body{
    overflow-x: hidden;
}

.container{
    max-width: 1000px;
    margin: auto;
}

hr{
    border: 1px solid #E1DBDB;
    margin: 30px 0;
}